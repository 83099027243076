import '../index.css';
import Marketplaces from './Marketplaces';
import React from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
const { useEffect, useRef, forwardRef } = React;

function ErrorPage() {

// GREENSOCK
const el = useRef();
const q = gsap.utils.selector(el);
const tl = useRef();
    
useEffect(() => {            
  tl.current = gsap.timeline({delay: 1})
    .to(q(".ErrorPage h4"), {opacity: 1, duration: 1})
    .to(q(".ErrorPage p"), {opacity: 1, duration: 1},0.5)
    .to(q(".ErrorPage h2"), {opacity: 1, duration: 1},1);
}, []);


  return (
    <div className="ErrorPage" ref={el}>
      <h4>YOU'RE' LOST IN THE GRAVEYARD</h4>
      <p>Head back to safety. There are spirits out here. </p>
      <Link to="/">
        <h2>BOOGLE</h2>
      </Link>
    </div>
  );
}

export default ErrorPage;

