import '../index.css';
import React, { useState, useEffect, useRef} from "react";
import { gsap, Power4 } from "gsap";
import {useNavigate, useLocation} from "react-router-dom";



function useWindowDimensions() {

  // let intital

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [newwidth, setNewwidth] = useState(200);


  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    if(window.innerWidth <= 900){
      document.getElementsByClassName('Primary')[0].style.width = (document.getElementsByClassName('App-Col-Right')[0].offsetWidth - 20) + "px";
    }
    if(window.innerWidth <= 850){
      document.getElementsByClassName('MarketDivider')[0].style.width = (document.getElementsByClassName('InnerPrimary')[0].offsetWidth ) + "px";
    }
    if (window.innerWidth >= 900){
      document.getElementsByClassName('Primary')[0].style.width = (document.getElementsByClassName('App-Col-Right')[0].offsetWidth - 110) + "px";
      document.getElementsByClassName('MarketDivider')[0].style.width = (document.getElementsByClassName('InnerPrimary')[0].offsetWidth ) + "px";
    }


    
  };

  useEffect(() => {


    if(window.innerWidth <= 900){
      document.getElementsByClassName('Primary')[0].style.width = (document.getElementsByClassName('App-Col-Right')[0].offsetWidth - 20) + "px";
    }
    else if (window.innerWidth >= 900){
      document.getElementsByClassName('Primary')[0].style.width = (document.getElementsByClassName('App-Col-Right')[0].offsetWidth - 110) + "px";
    }
  }, []);

  useEffect(() => {

    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return {
    newwidth
  }
}

function Marketplaces(props) {

  const [title, setTitle] = useState("");
  const [animCount, setAnimCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let pathname;
  const { newwidth } = useWindowDimensions()
  let allfixed = false;




  useEffect(() => {
    pathname = window.location.pathname;
    if (location.pathname === '/gallery') {
      setTitle("single view");
      allfixed = true;
      
    } else if (location.pathname.includes("/gallery/boogle")) {
      setTitle("gallery view");
      allfixed = true;
    }
    else {
      setTitle("");
      allfixed = false;
    }
  }, [location]);


  let changePage = () => {
    if(location.pathname == "/gallery") {
      navigate("/gallery/boogle1")
    }
    else if (location.pathname.includes("/gallery/boogle")) {
      navigate("/gallery")
    }
  }

  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef(); 

  useEffect(() => {
      let contentWidth = document.getElementsByClassName('Secondary')[0].offsetWidth;
      tl.current = gsap.timeline()
      .to(q(".Primary"), {opacity: 1, duration: 1.2, ease: Power4.easeOut})
      .to(q(".MarketDivider"), {width: contentWidth, duration: 4, ease: Power4.easeOut}, "-=0.5")
      .to(q(".Secondary"), {opacity: 1, stagger: 0.7, duration: 1, ease: Power4.easeOut}, "-=2.5");
  }, []);





  return (
    <div className='Topbar'ref={el}>
    <div className="Marketplaces"  >

      <div className='Primary Row'>
        <div className='InnerPrimary'>
          <a href="https://exchange.art/series/BOOGLE%20GEN%201/nfts" target="_blank" rel="noopener noreferrer">
            <div className='Description'>SECONDARY MARKETPLACE</div>
            <div className='Title'>EXCHANGE</div>
            <div className='Arrow'>↗</div> 
          </a>
          <div className='MarketDivider'></div>
        </div>
      </div>

      <div className={"Column-Left"}>
        <div className='PageTitle' onClick={changePage} >{title}</div>
      </div>

      <div className={"Column-Right"}>      
          <div className='Secondary Row'>
          <a href="https://magiceden.io/marketplace/boogle_gen_1">
            <div className='Description'>SECONDARY MARKETPLACE</div>
            <div className='Title'>MAGIC EDEN</div>
            <div className='Arrow'>↗</div> 
          </a>
          </div>
      </div>
        
      
    </div>
    </div>
  );
}

export default Marketplaces;
