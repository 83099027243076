import React, { useState, useEffect, useRef } from 'react';


const MailchimpForm = () => {


    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState('');



    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault(); // Prevent default form submission if event is present
        }

        // Immediately replace the form with the success message
        setSubmitted(true);

        // Prepare the form data for Mailchimp
        const formData = new URLSearchParams({
            EMAIL: email,
            u: '41b5d3317e43bac1267965436', // Mailchimp user ID
            id: '0ab2cef413',               // Mailchimp audience/list ID
        });

        // Submit the form data to Mailchimp
        fetch('https://rip.us10.list-manage.com/subscribe/post?', {
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            mode: 'no-cors', // Bypass CORS issues
        }).catch(error => {
            console.error('Mailchimp submission error:', error);
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <div id="mc_embed_signup">
            {submitted ? (
                <div className="ThankYou">
                    THE GHOSTS WILL BE IN TOUCH.
                </div>
            ) : (
                <form
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    onSubmit={handleSubmit}
                >
                    <div id="mc_embed_signup_scroll">
                        <div className="mc-field-group">
                            <input
                                placeholder="YOUR EMAIL"
                                type="email"
                                name="EMAIL"
                                className="required email"
                                id="mce-EMAIL"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                onKeyDown={handleKeyDown} // Ensure Enter key submits the form even if user is focused on the input
                            />
                        </div>
                        <div id="mce-responses" className="clear foot"></div>
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                            <input
                                type="text"
                                name="b_41b5d3317e43bac1267965436_0ab2cef413"
                                tabIndex="-1"
                                value=""
                                readOnly
                            />
                        </div>
                        <div className="optionalParent">
                            <div
                                className="Subscribe-button"
                                onClick={handleSubmit}
                                role="button"
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                            >
                                SIGN UP
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default MailchimpForm;