import React from "react";
import '../index.css';
import { Routes, Route, useLocation } from "react-router-dom";
import MailchimpForm from './Mailchimp';
import { gsap } from "gsap";
const { useEffect, useRef, useState } = React;

function App() {


  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();

  useEffect(() => {            
      tl.current = gsap.timeline({delay: 0.5})
        .to(q("h1"), {opacity: 1, duration: 3})
        .to(q("p"), {opacity: 1, duration: 2}, "-=1.5")
        .to(q("#mc_embed_signup"), {opacity: 1, duration: 1}, "-=1")
        .to(q(".AsiaTour"), {opacity: 1, duration: 1}, "-=0.5")
        .to(q(".SocialIcon"), {opacity: 1, duration: 1,stagger: 0.8}, "-=0")

    }, []);

  return (
    <div className="App" ref={el}       
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/img/boogle-black-and-white/BOOGLE_2024W.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh', // Example height
  }}
  >




    <div className="BackgroundOverlay">
    <div className="LandingPage">

      <h1> BOOGLE </h1>
      <p> OUR WEBSITE AND STORE IS CURRENTLY CLOSED </p>
      <p> SIGN UP TO BE NOTIFIED ON WHEN THE SITE REOPENS, NEWS, EVENTS AND RELEASES </p>
      <MailchimpForm/>
      <div className="AsiaTour"><a href="https://asia-tour.boogle.rip"> ATTEND ASIA TOUR 2024 </a> </div>
      <div className="SocialFooter">
        <div className="SocialIcon"><a href="https://www.youtube.com/watch?v=x6I2WVNvw68&list=PLTrG8Vd9ZQcnX-xonGaUY9w2Hh0OBiOoE&index=20" target="_blank"><div className="Circle">1</div></a></div>
        <div className="SocialIcon"><a href="https://x.com/solBOOGLE"><img src="img/social/x-twitter-brands-solid.svg" /></a></div>
        <div className="SocialIcon"><a href="https://www.instagram.com/BOOGLE.rip"><img src="img/social/instagram-brands-solid.svg"/></a></div>
      </div>
     
    </div>
    </div>
    </div>
  );
}


export default App;
